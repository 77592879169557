import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useRequest, useInterval } from 'ahooks';
import Panel from '@/components/Panel/index'
import TopTable from '@/components/topTable'
import { Spin, Button } from 'antd';
import { GetGrovernFeedbackData } from '@/services/api';
import ImgSource from '@/assets/img/tingchenan/demand.png'
import style from './style.module.scss'
export interface ParkingFeedbackProps {
  areaCode?: string;
}

const $ = (selector: string) => document.querySelector(selector);

const ParkingFeedback:React.FC<ParkingFeedbackProps> = props => {
  const [scrollInterval, setScrollInterval] = useState<number | null>(null);
  const [allowRefresh,setAllowRefresh] = useState(true);

  const { data = [], loading, error, refresh } = useRequest(useCallback(() => {
    return GetGrovernFeedbackData(props.areaCode)
  }, [props.areaCode]), {
    formatResult: res => res.data,
    refreshDeps: [props.areaCode]
  })

  const scrollContainer = useCallback(() => {
    const container = $('.inspector-feed-table .ant-table-body');
    if (container !== null) {
      const { clientHeight, scrollHeight, scrollTop } = container;
      let height = scrollTop + 1;
      const top = scrollHeight - clientHeight - height;
      if (top <= 0) {
        height = 0
      }

      if (scrollHeight <= clientHeight) {
        setScrollInterval(null)
      }
      
      container.scrollTo(0,height)
    }
  }, [])

  const stopTick = useCallback(() => {
    setAllowRefresh(false);
    setScrollInterval(null)
  }, [])
  
  const startTick = useCallback(() => {
    setAllowRefresh(true);
    setScrollInterval(100)
  }, [])

  useInterval(() => {
    scrollContainer()
  }, scrollInterval)

  useInterval(() => {
    //refresh();
  },allowRefresh ? 60000 : null)

  useEffect(() => {
    const container = $('.inspector-feed-table .ant-table-body')
    if (container) {
      const { clientHeight, scrollHeight } = container;
      const isOver = scrollHeight > clientHeight
      if (isOver) {
        setScrollInterval(100)
      }
    }
  }, [data])

  if (loading) {
    return (
      <Panel title="群众反应问题" >
        <div className="fetch_container" style={{ height: 200 }}>
          <Spin/>
        </div>
      </Panel>
    )
  }

  if (error) {
    return (
      <Panel title="群众反应问题" >
        <div className="fetch_container" style={{ height: 200 }}>
          请求错误
        </div>
      </Panel>
    )
  }
  return (
    <Panel
      title="群众反应问题" 
    >
      {/* <div onMouseEnter={stopTick} onMouseLeave={startTick}> */}
      <div>
        <TopTable
          className={`${style.feed_table} inspector-feed-table`}
          dataSource={data}
          style={{height: '196px'}}
          rowKey={record => record.name}
          columns={[
            { title: '手机车主昵称',width:'120px',  dataIndex: 'name' },
            { title: '反馈问题', dataIndex: 'content',render: (text, record) => (
              <div style={{  whiteSpace:'nowrap', textOverflow: 'ellipsis',paddingLeft:'8px', overflow: 'hidden'}}>
                {record.content}
              </div>
            ), },
          
          ]}
          // scroll={{y: 239}}s
        />
      </div>
    </Panel>
  )
}

export default ParkingFeedback;