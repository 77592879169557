import React from 'react';
import { Modal } from 'antd';
import styles from './index.module.scss';

const PictureWindow:React.FC<{
  visible: boolean;
  onClose: () => void;
  extraInfo?: any;
  src: string;
}> = ({visible,onClose,src,extraInfo}) => {
  return (
    <Modal
      className={styles.picture_window}
      width={520}
      footer={null}
      visible={visible}
      onCancel={onClose}
      closeIcon={<img src="/img/icon_close_circle.png" alt="" className={styles.picture_window_close} />}
      maskStyle={{background: 'transparent'}}
    >
     
      {src
      ?
      extraInfo != undefined &&<> <div className={styles.picture_info}>
      { extraInfo?.carType != undefined &&  <div>车辆类型：{extraInfo.carType}</div>}
      { extraInfo?.carBand != undefined &&  <div>车辆品牌：{extraInfo.carBand}</div>}
      { extraInfo?.carColor != undefined &&  <div>颜色：{extraInfo.carColor}</div>}
      { extraInfo?.carYear != undefined &&  <div>车辆年款：{extraInfo.carYear}</div>}
    </div>
    
      <img width="492" src={src} alt=""/>
      </>
      :
      <div  className={styles.nopicture_text}>暂无图片</div>}
    </Modal>
  )
}

export default PictureWindow;