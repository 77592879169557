/** 智能改造泊位分布 */
import { GetDeviceStatic } from '@/services/api'
import { useRequest } from 'ahooks'
import { Spin } from 'antd'
import React, { useCallback, useMemo, useState, useEffect ,forwardRef} from 'react'
import Panel from '@/components/Panel/index'
import styles from './style.module.scss'
import ImgSource from '@/assets/img/resource/bowei.png'
import Abutton from '@/components/Abutton'
/** 设备管理跳转地址 */
const iframeURL = "http://lz.dgjx0769.com/lz/#/UrbanIntegration/BasicDataManage/DeviceManage";

  const ParkingDeviceCount:React.FC<{
   areaCode?: string;
   onDeviceTypeChange:(val:number)=>void;
  }> = forwardRef(({areaCode, onDeviceTypeChange},ref) => {
  // const service = useCallback(() => {
    
  //   return GetDeviceStatic(props.areaCode )
  // }, [props.areaCode])
  // const { loading, data, error } = useRequest(service, {
  //   refreshDeps: [props.areaCode]
  // })

  const { loading, data, error } = useRequest(useCallback(() => {
    return GetDeviceStatic(areaCode )
  },[areaCode]), {
    refreshDeps: [areaCode]
  })

  if (error && loading === false) {
    <Panel
      title="智能改造泊位分布"
    >
      <div className="fetch_container" style={{ height: 89 }}>
        请求错误
      </div>
    </Panel>
  }

  const AbuttonBox = useMemo(() => {
    return <Abutton iframeURL={iframeURL} text={''} />
  }, [])

  const deviceChange = (val: number) => {
    //window.open(iframeURL, '_blank');
    onDeviceTypeChange(val);
  }
  
  return (
    <Panel
      title="智能改造泊位分布"
     
    >
      
      
      {
        loading || !data?.data ?
          <div className="fetch_container" style={{ height: 250 }}>
            <Spin />
          </div> :
        data?.code ==200 &&
          
          <div className={styles.container}>
            <div className={styles.allbtn}  onClick={()=>deviceChange(0)}> 全部</div>
            <div className={styles.item} onClick={() =>deviceChange(4)}>
              <div className={styles.img} >
                <img src="/img/high_video_stake.png" alt="" />
              </div>
              <div className={styles.number}>{data.data.highCount}/{data.data.highIncome} </div>
              <div className={styles.label}>数量/收入</div>
            </div>
            <div className={styles.item} onClick={() =>deviceChange(1)}>
              <div className={styles.img} >
                <img src="/img/geomagnetism.png" alt="" />
              </div>
              <div className={styles.number}>{data.data.geoCount}/{data.data.geoIncome}</div>
              <div className={styles.label}>数量/收入</div>
            </div>
            <div className={styles.item} onClick={() =>deviceChange(3)}>
              <div className={styles.img} >
                <img src="/img/median_video_stake.png" alt="" />
              </div>
              <div className={styles.number}>{data.data.meddleCount}/{data.data.meddleIncome}</div>
              <div className={styles.label}>数量/收入</div>
            </div>
            <div className={styles.item} onClick={() =>deviceChange(6)}>
              <div className={styles.img} >
                <img src="/img/Barrier.png" alt="" />
                <span>社会</span>
              </div>
              <div className={styles.number}>{data.data.socialGateCount}/{data.data.socialGateIncome}</div>
              <div className={styles.label}>数量/收入</div>
            </div>
            <div className={styles.item} onClick={() =>deviceChange(5)}>
              <div className={styles.img} >
                <img src="/img/Barrier.png" alt="" />
                <span>自有</span>
              </div>
              <div className={styles.number}>{data.data.ownGateCount}/{data.data.ownGateIncome}</div>
              <div className={styles.label}>数量/收入</div>
            </div>
          </div>
      }
    </Panel>
  )
})

export default ParkingDeviceCount