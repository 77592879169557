import React from 'react';
import { Progress } from 'antd';
import styles from './index.module.scss';

const Loading:React.FC<{
  progress: number;
}> = ({progress}) => {
  return (
    <Progress className={styles.progress} percent={progress} status="active" showInfo={false} />
  )
}

export default Loading;