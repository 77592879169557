import React, { useState } from 'react';
import Select from '@/components/Select';
import { Input } from 'antd';
import {SearchOutlined} from '@ant-design/icons';
import styles from './index.module.scss';

const typeList = [
  { label: '车牌', value: 1 },
  { label: '路段名', value: 2 },
  { label: '车场名', value: 3 }
]

const typeNames: {
  [key: number]: string;
} = {
  1: '车牌',
  2: '路段名',
  3: '车场名'
}

const Search:React.FC<{
  onSearch: (value: string, searchType: number) => void;
}> = ({onSearch}) => {
  const [type,setType] = useState(1);
  return (
    <div className={`${styles.search} ${styles.search_sm}`}>
      <Select
        className={styles.search_select}
        dropdownMatchSelectWidth={120}
        options={typeList}
        defaultValue={1}
        onChange={(value) => setType(value)}
      />
      <div className={styles.search_divide}></div>
      <Input.Search
        className={styles.search_input}
        placeholder={`请输入${typeNames[type]}`}
        enterButton={<img src="/img/icon_search_white.png" alt=""/>}
        onSearch={value => onSearch(value, type)}
      />
      
    </div>
  )
}

export default Search;