import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useRequest, useInterval } from 'ahooks';
import Panel from '@/components/Panel/index'
import TopTable from '@/components/topTable'
import { Spin, Button } from 'antd';
import { GetJoinParkIncomeRank } from '@/services/api';
import ImgSource from '@/assets/img/jingying/jierutingchechangshouru@2x.png'
export interface ParkingLostRankingProps {
  areaCode?: string;
}

const $ = (selector: string) => document.querySelector(selector);

const ParkingLostRanking:React.FC<ParkingLostRankingProps> = props => {
  const [scrollInterval, setScrollInterval] = useState<number | null>(null);
  const [allowRefresh,setAllowRefresh] = useState(true);

  const { data = [], loading, error, refresh } = useRequest(useCallback(() => {
    return GetJoinParkIncomeRank(props.areaCode)
  }, [props.areaCode]), {
    formatResult: res => res.data,
    refreshDeps: [props.areaCode]
  })

  const scrollContainer = useCallback(() => {
    const container = $('.inspector-parklot-table .ant-table-body');
    if (container !== null) {
      const { clientHeight, scrollHeight, scrollTop } = container;
      let height = scrollTop + 1;
      const top = scrollHeight - clientHeight - height;
      if (top <= 0) {
        height = 0
      }

      if (scrollHeight <= clientHeight) {
        setScrollInterval(null)
      }
      
      container.scrollTo(0,height)
    }
  }, [])

  const stopTick = useCallback(() => {
    setAllowRefresh(false);
    setScrollInterval(null)
  }, [])
  
  const startTick = useCallback(() => {
    setAllowRefresh(true);
    setScrollInterval(100)
  }, [])

  useInterval(() => {
    scrollContainer()
  }, scrollInterval)

  useInterval(() => {
    refresh();
  },allowRefresh ? 60000 : null)

  useEffect(() => {
    const container = $('.inspector-parklot-table .ant-table-body')
    if (container) {
      const { clientHeight, scrollHeight } = container;
      const isOver = scrollHeight > clientHeight
      if (isOver) {
        setScrollInterval(100)
      }
    }
  }, [data])

  if (loading) {
    return (
      <Panel title="接入停车场收入TOP5" >
        <div className="fetch_container" style={{ height: 200 }}>
          <Spin/>
        </div>
      </Panel>
    )
  }

  if (error) {
    return (
      <Panel title="接入停车场收入TOP5" >
        <div className="fetch_container" style={{ height: 200 }}>
          请求错误
        </div>
      </Panel>
    )
  }
  const dataSource = [

    {
      key: '0',
      name: '江阳区',
      top:'TOP1',
      parkName:'西湖区湖底公园停车场',
      count:125255
    },
    {
      key: '1',
      name: '纳溪区',
      top:'TOP2',
      parkName:'西湖区湖底公园停车场',
      count:125255
    },
    {
        key: '2',
        name: '龙马潭区',
        top:'TOP3',
        parkName:'西湖区湖底公园停车场',
        count:125255
      },
      {
        key: '3',
        name: '纳溪区',
        top:'TOP4',
        parkName:'西湖区湖底公园停车场',
        count:125255
      },
      {
        key: '4',
        name: '纳溪区',
        top:'TOP5',
        parkName:'西湖区湖底公园停车场',
        count:125255
      },
  ];
  return (
    <Panel
      title="接入停车场收入TOP5" 
    >
     {/* <div onMouseEnter={stopTick} onMouseLeave={startTick}> */}
     <div>
      <TopTable
          className=" inspector-parklot-table"
          dataSource={data}
          style={{height: '196px'}}
          rowKey={record => record.parkId}
          columns={[
            { title: '排行', render:(text,record,index)=>`TOP${index+1}` },
            { title: '区域' , dataIndex: 'areaName'},
            { title: '车场名称', dataIndex: 'parkName' ,render: (text, record) => (
              <div style={{ width:'200px', whiteSpace:'nowrap', textOverflow: 'ellipsis', overflow: 'hidden'}}>
                {record.parkName}
              </div>
            ), },
            { title: '收入', dataIndex: 'income'}
          ]}
          
          // scroll={{y: 189}}
        />
      </div>
    </Panel>
  )
}

export default ParkingLostRanking;