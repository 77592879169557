import React, { useCallback } from 'react';
import { useRequest } from 'ahooks';
import Panel from '../index';
import Pillar from './components/Pillar';
import { Spin } from 'antd';
import { GetParkLevelInfo } from '@/services/api';
import styles from './index.module.scss';

export interface ParkingTrendProps {
  areaCode?: string;
}

const ParkingTrend:React.FC<ParkingTrendProps> = ({areaCode}) => {

  const { data, loading, error } = useRequest(useCallback(() => {
    return GetParkLevelInfo(areaCode);
  },[areaCode]), {
    formatResult: res => ({
      level1: res.data.find(item => item.parLevel === '1类'),
      level2: res.data.find(item => item.parLevel === '2类'),
      level3: res.data.find(item => item.parLevel === '2类')
    }),
    refreshDeps: [areaCode]
  })

  if(loading) {
    return (
      <Panel title="停车趋势等级划分">
        <div className="fetch_container" style={{ height: 280 }}>
          <Spin/>
        </div>
      </Panel>
    )
  }

  if(error) {
    return (
      <Panel title="停车趋势等级划分">
        <div className="fetch_container" style={{ height: 280 }}>
          请求错误
        </div>
      </Panel>
    )
  }
  return (
    <Panel
      title="停车趋势等级划分"
    >
      <div className={styles.trend_box}>
        <div className={styles.trend_item}>
          <div className={styles.trend_title}>等级：1类</div>
          <Pillar level="1" />
          <div className={styles.trend_info}>{data?.level1?.parkUseRatio}</div>
        </div>
        <div className={styles.trend_item}>
          <div className={styles.trend_title}>等级：2类</div>
          <Pillar level="2" />
          <div className={styles.trend_info}>{data?.level2?.parkUseRatio}</div>
        </div>
        <div className={styles.trend_item}>
          <div className={styles.trend_title}>等级：3类</div>
          <Pillar level="3" />
          <div className={styles.trend_info}>{data?.level3?.parkUseRatio}</div>
        </div>
      </div>
    </Panel>
  )
}

export default ParkingTrend;