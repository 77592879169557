import React, { useCallback } from 'react';
import { useRequest } from 'ahooks';
import Panel from '@/components/Panel';
import { Area } from '@ant-design/charts';
import { GetAddIncomeRank } from '@/services/api';
import { Spin } from 'antd'
import moment from 'moment'
import ImgSource from '@/assets/img/jingying/shouruyuequshi@2x.png'

export interface ParkingRechargeIncomeProps {
  areaCode?: string;
}

const ParkingRechargeIncome:React.FC<ParkingRechargeIncomeProps> = ({areaCode}) => {
  const { data = [], loading, error } = useRequest(useCallback(() => {
    return GetAddIncomeRank(areaCode,'450200');
  },[areaCode]), {
    formatResult: res => res.data,
     
    refreshDeps: [areaCode]
  })

  if(loading) {
    return (
      <Panel title="充值收入"  >
        <div className="fetch_container" style={{ height: 200 }}>
          <Spin/>
        </div>
      </Panel>
    )
  }

  if(error) {
    return (
      <Panel title="充值收入"  >
        <div className="fetch_container" style={{ height: 200 }}>
          请求错误
        </div>
      </Panel>
    )
  }

   // eslint-disable-next-line array-callback-return
  let newdata=  data?.map((item: any,index: any)=>{
      let day=item.days>9?item.days:'0'+item.days
      
      if(item.days<moment().date()){
        item.time=(moment().month()+1)+'/'+day
      }else{
        item.time=(moment().month())+'/'+day
      }
     
     return item
   })
 
  // let newdata=[
  //   {day:'09/01', income: 123},
  //   {day:'09/02', income: 246},
  //   {day:'09/03', income: 369},
  //   {day:'09/04', income: 492},
  //   {day:'09/05', income: 615},
  //   {day:'09/06', income: 738},
  //   {day:'09/07', income: 861},
  // ]
  return (
    <Panel
      title="充值收入"  
    >
      <Area
        height={200}
        padding={[20,20,23,48]}
        smooth
        color="#01aef8"
        data={newdata}
        xField="time"
        yField="addIncomeTotal"
        line={{
          color:'#01aef8',
        }}
       
        xAxis={{
          // type:'time',
          
           label: {
           
            style: {
              fill: '#fff'
            }
          },
          range:[0, 1],
          tickInterval:1,
          tickLine: null    //取消刻度线
        }}
        yAxis={{
          
          label: {
            style: {
              fill: '#fff'
            },
            formatter: v => `${v}`,
          },
          line: {
            style: {
              stroke: '#12708D'
            }
          },
          grid: {
            line: {
              style: {
                stroke: '#12708D',
                strokeOpacity: 0.4
              }
            }
          },
          minTickInterval: 50,
          min: 0
        }}
        areaStyle={{
          fill: 'l(90) 1：#ffffff 0.5:#01aef8 0: #01aef8'
        }}
        tooltip={{
          formatter: (data) => {
            return {
              name: `总收入`,
              value: `${data?.addIncomeTotal}`
            }
          },
          showTitle: false
        }}
        animation={{
          appear: {
            animation: 'path-in',
            duration: 3000
          },
          update: {
            easing: 'easeLinear',
            duration: 3000,
          }
          
        }}
      />
    </Panel>
  )
}

export default ParkingRechargeIncome;