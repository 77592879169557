/// <reference path="./API.d.ts" />
import request from './request';

export async function GetCityInfo() {
  return request.get<API.CityInfoData>('cities/cities');
}

export async function GetCityAreaInfo(cityCode?: string,queryTime?:string) {
  return request.get<API.AreaInfoData>('area', {
    params: {
      cityCode,
      queryTime
    }
  });
}

export async function GetCityWeather(cityCode?: string) {
  return request.get('common/weather/info', {
    params: {
      cityCode
    }
  })
}

export async function GetRoadListByCity(areaCode?: string,trialRunStatus?: string) {
  return request.get<API.RoadList>('road-section', {
    params: {
      areaCode,
      trialRunStatus
    }
  })
}

export async function GetDeviceStatic(areaCode?:string){
return request.get('resource/device-static',{
  params:{
    areaCode
  }
})
}

export async function GetParkAlarm(areaCode?: string) {
  return request.get<API.ParkAlarmData>('resource/alarm', {
    params: {
      areaCode
    }
  })
}

export async function GetParkRank(params: {
  areaCode?: string;
  limit?:number;
}) {
  return request.get('resource/park-rank', {
    params
  })
}
export async function GetRoadRank(params: {
  areaCode?: string;
  limit?:number;
}) {
  return request.get('resource/road-rank', {
    params
  })
}

export async function GetDistributeData(areaCode?: string) {
  return request.get<API.DistributeData[]>('resource/distribute', {
    params: {
      areaCode
    }
  })
}

export async function GetDifficultParkOrRoad(params: {
  areaCode?: string,
  limit?:string
}) {
  return request.get('resource/park-difficult', {
    params
  })
}
export async function GetDifficultRoadOrRoad(params: {
  areaCode?: string
  limit?:string
}) {
  return request.get('resource/road-difficult', {
    params
  })
}

export async function GetServiceIncomeRank(areaCode?: string) {
  return request.get<API.ServiceIncomeRank[]>('operate/income/add-service/rank', {
    params: {
      areaCode
    }
  })
}

export async function GetIncomeTrendDay(areaCode?: string,cityCode?: string) {
  return request.get<API.IncomeTrendDay[]>('operate/income/trend/day', {
    params: {
      areaCode,
      cityCode
    }
  })
}

export async function GetIncomeTrendMonth(areaCode?: string,cityCode?: string) {
  return request.get<API.IncomeTrendMothy[]>('operate/income/trend/month', {
    params: {
      areaCode,
      cityCode
    }
  })
}
export async function GetAddIncomeRank(areaCode?: string,cityCode?: string) {
  return request.get('operate/income/add-income/rank', {
    params: {
      areaCode,
      cityCode
    }
  })
}

export async function GetJoinParkIncomeRank(areaCode?: string) {
  return request.get<API.JoinParkIncomeRank[]>('operate/income/join-park/rank', {
    params: {
      areaCode
    }
  })
}

export async function GetJoinParkNew(areaCode?: string) {
  return request.get<API.JoinParkNew[]>('operate/income/join-park/new', {
    params: {
      areaCode
    }
  })
}

export async function GetIllegalPark(areaCode?: string,limit?: string) {
  return request.get<API.IllegalParkData[]>('supervise/illegal-park', {
    params: {
      areaCode,
      limit
    }
  })
}

export async function GetunPaidCar(areaCode?:string,limit?: string) {
  return  request.get<API.UnpaidCarData[]>('supervise/unpaid-car',{
    params:{
      areaCode,
      limit
    }
  })
}
//<API.InspectorReport[]>
export async function GetInspectorReport(areaCode?: string) {
  return request.get('resource/inspector/report/list', {
    params: {
      areaCode,
      pageNum:'1',
      pageSize:'15'
    }
  })
}

export async function GetCarBreakData(areaCode?: string,limit?: string) {
  return request.get<API.CarBreakData[]>('supervise/break', {
    params: {
      areaCode,
      limit
    }
  })
}

export async function GetExceptioncCarData(areaCode?: string,limit?: string) {
  return request.get('supervise/exception/car', {
    params: {
      areaCode,
      limit
    }
  })
}

export async function GetBlindSpotData(
  areaCode?: string) {
  return request.get('grovern/blind-spot', {
    params: {
      areaCode
    }
  })
}
export async function GetBlindSpotRoadData( areaCode?: string ,limit?:string) {
  return request.get('grovern/blind-spot-road', {
    params: {
      areaCode,
      limit
    }
  })
}
export async function GetGrovernRequireData(areaCode?:string,limit?:string) {
  return request.get<API.GrovernRequireData[]>('grovern/parking-demand', {
    params: {
      areaCode,
      limit
    }
  })
}

export async function GetGrovernFeedbackData(areaCode?:string) {
  return request.get<API.GrovernFeedbackData[]>('grovern/feedback', {
    params: {
      areaCode
    }
  })
}

export async function GetDifficultGridData(
  areaCode?: string) {
  return request.get<API.DifficultGridData>('grovern/difficult-grid', {
    params: {
      areaCode
    }
  })
}

export async function GetGrovernUseRateFourData(
  areaCode?: string) {
  return request.get<API.GrovernUseRateData>('grovern/use-rate-four', {
    params: {
      areaCode
    }
  })
}
export async function GetGrovernUseRateOneData(
  areaCode?: string) {
  return request.get<API.GrovernUseRateData>('grovern/use-rate-one', {
    params: {
      areaCode
    }
  })
}
export async function GetGrovernUseRateThreeData(
  areaCode?: string) {
  return request.get<API.GrovernUseRateData>('grovern/use-rate-three', {
    params: {
      areaCode
    }
  })
}
export async function GetGrovernUseRateTwoData(
  areaCode?: string) {
  return request.get<API.GrovernUseRateData>('grovern/use-rate-two', {
    params: {
      areaCode
    }
  })
}
export async function GetGrovernParkUseRateFourData(
  areaCode?: string
  ) {
  return request.get<API.GrovernUseRateData>('grovern/park-use-rate-four', {
    params: {
      areaCode,
      status:'Y'
    }
  })
}
export async function GetGrovernParkUseRateOneData(
  areaCode?: string) {
  return request.get<API.GrovernUseRateData>('grovern/park-use-rate-one', {
    params: {
      areaCode,
      status:'Y'
    }
  })
}
export async function GetGrovernParkUseRateThreeData(
  areaCode?: string) {
  return request.get<API.GrovernUseRateData>('grovern/park-use-rate-three', {
    params: {
      areaCode,
      status:'Y'
    }
  })
}
export async function GetGrovernParkUseRateTwoData(
  areaCode?: string) {
  return request.get<API.GrovernUseRateData>('grovern/park-use-rate-two', {
    params: {
      areaCode,
      status:'Y'
    }
  })
}

export async function GetAreaStaticData(
  cityCode?: string) {
  return request.get<API.AreaStaticData>('area/static', {
    params: {
      cityCode
    }
  })
}



export async function GetParkInfo(params: {
  parkId: string;
  date: string;
  time: string;
}) {
  return request.get<API.ParkInfo>('/park_resource/default/road_size/park_click', {
    params
  })
}
export async function GetparklotInfo(params: {
  parkId: string;
  queryTime: string;
}) {
  return request.get<API.RoadDetail>('park-lot/detail', {
    params
  })
}
export async function GetParkLotOrderList(params: {
  parkId: string;
  pageNum?: string;
  pageSize?: string;
}) {
  return request.get('park-lot/park-order', {
    params
  })
}

export async function GetstaffInfo(params: {
  eventManageId?: string;
  pageNum?: string;
  pageSize?: string;
}) {
  return request.get('inspector-lot/get-work-order', {
    params
  })
}
export async function GetreportInfo(params: {
  pageNum?: string;
  pageSize?: string;
}) {
  return request.get('resource/inspector/report', {
    params
  })
}
export async function GetRoadInfo(params: {
  roadSectionId: string;
  queryTime: string;
}) {
  return request.get<API.RoadDetail>('road-section/detail', {
    params
  })
}
export async function GetRoadprogram(params: {
  roadSectionId: string;
}) {
  return request.get<API.RoadDetail>('road-section/program', {
    params
  })
}
export async function GetInspectorInfont(params: {
  id: string;
}) {
  return request.get('inspector-lot/get-inspector', {
    params
  })
}
export async function Getparklotprogram(params: {
  parkId: string;
}) {
  return request.get<API.RoadDetail>('park-lot/in-out', {
    params
  })
}

export async function GetAreaInfo(params: {
  areaId: string;
  date: string;
  time: string;
}) {
  return request.get<API.AreaInfo>('/park_resource/default/area_size/click', {
    params
  })
}

export async function GetCarParkInfo(orderNo: string) {
  return request.get<API.CarParkInfo>('/park_resource/default/search/clilck/type_one', {
    params: {
      orderNo
    }
  })
}
export async function GetStreetparking(data: {
  roadSectionId: string;
  areaCode?: string;
  queryTime?:string;
}) {
  return request.get('parking-no',{
    params: data
  })
}

export async function GetStreetParkInfo(data: {
  roadSectionId: string;
  berthId: string;
  queryTime?:string;
}) {
  return request.get<API.StreetParkInfo>('parking-no/detail',{
    params: data
  })
}
export async function GetStreetParkList(data: {
  berthId?: string;
  berthNo?:string;
  plateNo?:string;
  queryTime?:string;
}) {
  return request.get('parking-no/berth-order-detail',{
    params: data
  })
}
export async function GetStreetParkImage(orderId: string) {
  return request.get('parking-no/photo', {
    params: {
      orderId
    }
  })
}

export async function GetOperateIncome(areaCode?:string) {
  return request.get<API.OperateIncome>('operate/income/add-income/rank',{
    params:{
      areaCode
    }
  })
}


export async function GetSummaryTrafficInfo(areaCode?: string) {
  return request.get<API.SummaryTrafficData>('/park_resource/traffic_count', {
    params: {
      areaCode
    }
  })
}

export async function GetSummaryUserInfo(areaCode?: string) {
  return request.get<API.SummaryUserData>('/park_resource/summary_count', {
    params: {
      areaCode
    }
  })
}

export async function GetSummaryEffectiveInfo(areaCode?: string) {
  return request.get<API.SummaryEffectiveData>('/park_effective/summary_count', {
    params: {
      areaCode
    }
  })
}

export async function GetAlarmCountData(areaCode?: string) {
  return request.get<API.AlarmCountData>('/park_resource/alarm_count', {
    params: {
      areaCode
    }
  })
}

export async function GetAlarmTypeInfo(areaCode?: string) {
  return request.get<API.AlarmTypeList>('/park_resource/get_alarm_one', {
    params: {
      areaCode
    }
  })
}

export async function GetAlarmListByType(params: {
  areaCode?: string;
  alarmType: number;
}) {
  return request.get<API.AlarmList>('/park_resource/get_alarm_two', {
    params
  })
}

export async function GetGridInfo(subZoneId: string) {
  return request.get<API.GridInfo>('/zone/detail', {
    params: {
      subZoneId
    }
  })
}

export async function GetParkingSummary(params: {
  areaCode?: string
}) {
  return request.get<API.ParkingSummary>('/park_resource/park_num/get', {
    params
  })
}

export async function GetZoneList() {
  return request.get<API.ZoneItem[]>('/zone')
}

export async function GetParkCount(params: {
  areaCode?: string
  zoneId: string
}) {
  return request.get<API.ParkCountItem[]>('/park_resource/park_count/get', {
    params
  })
}

export async function GetParkTimeWithZone(params: {
  areaCode?: string
}) {
  return request.get<API.ZoneParkTime>('/park_resource/park_time/get', {
    params
  })
}

export async function GetBusyParkOrRoad(params: {
  areaCode?: string
}) {
  return request.get<API.BusyArea[]>('/park_resource/park_diffcult/get', {
    params
  })
}

export async function GetRatioDataByType(
  areaCode?: string) {
  return request.get<API.ConverRatioData[]>('resource/industry', {
    params:{
      areaCode
    }
  })
}

export async function GetUseRatioByZone(params: {
  areaCode?: string
  zoneId: string
}) {
  return request.get<API.UseRatioItem[]>('/park_resource/use_ratio/get', {
    params
  })
}

export async function GetInspectorList(areaCode?: string) {
  return request.get<API.InspectorItem[]>('/park_resource/inspector/get_data', {
    params: {
      areaCode
    }
  })
}

export async function GetParkingNumberInfo(params: {
  areaCode?: string;
  zoneId?: string;
}) {
  return request.get<API.ParkingNumberData[]>('/park_effective/park_count/get', {
    params
  })
}

export async function GetGridUsageInfo(areaCode?: string) {
  return request.get<API.GridUsageData>('/park_effective/use_ratio/all', {
    params: {
      areaCode
    }
  })
}

export async function GetGridUsageLineInfo(params: {
  areaCode?: string;
  zoneId?: string;
}) {
  return request.get<API.GridUsageLineData[]>('/park_effective/use_ratio/by_city_zone', {
    params
  })
}

export async function GetFocusRatioInfo(areaCode?: string) {
  return request.get<API.FocusRatioData[]>('/park_effective/focus_ratio/by_city', {
    params: {
      areaCode
    }
  })
}

export async function GetSummaryTrendInfo(areaCode?: string) {
  return request.get<API.SummaryTrendData>('/park_trend/summary_count', {
    params: {
      areaCode
    }
  })
}

export async function GetGridFocusInfo(areaCode?: string) {
  return request.get<API.GridFocusData>('/park_trend/focus_ratio/all', {
    params: {
      areaCode
    }
  })
}

export async function GetGridFocusLineInfo(params: {
  areaCode?: string;
  zoneId?: string;
}) {
  return request.get<API.GridFocusLineData[]>('/park_trend/facus_ratio/by_city_zone', {
    params
  })
}

export async function GetParkLevelInfo(areaCode?: string) {
  return request.get<API.ParkLevelData[]>('/park_trend/park_level/by_city', {
    params: {
      areaCode
    }
  })
}

export async function GetParkNervousInfo(areaCode?: string) {
  return request.get<API.ParkNervousData[]>('/park_trend/park_nervous/by_city', {
    params: {
      areaCode
    }
  })
}

export async function GetTopNervousInfo(areaCode?: string) {
  return request.get<API.TopNervousData[]>('/park_trend/park_nervous/top', {
    params: {
      areaCode
    }
  })
}

export async function GetParkGapInfo(areaCode?: string) {
  return request.get<API.ParkGapData[]>('/park_trend/park_gap/by_street_park', {
    params: {
      areaCode
    }
  })
}

export async function GetSummaryMonitorInfo(areaCode?: string) {
  return request.get<API.SummaryMonitorData>('/park_monitor/summary_count', {
    params: {
      areaCode
    }
  })
}

export async function GetParkInInfo(areaCode?: string) {
  return request.get<API.ParkInData[]>('/park_monitor/park_new/in_park', {
    params: {
      areaCode
    }
  })
}

export async function GetParkActiveInfo(params: {
  areaCode?: string;
  zoneId?: string;
}) {
  return request.get<API.ParkActiveData[]>('/park_monitor/active_plate/by_city_zone', {
    params
  })
}

export async function GetParkMonitorInfo(params: {
  areaCode?: string;
  zoneId?: string;
}) {
  return request.get<API.ParkMonitorData[]>('/park_monitor/important_plate/by_city_zone', {
    params
  })
}

export async function GetSummaryGovInfo(areaCode?: string) {
  return request.get<API.SummaryGovData>('/park_govern/summary_count', {
    params: {
      areaCode
    }
  })
}

export async function GetParkBlindInfo(areaCode?: string) {
  return request.get<API.ParkBlindData>('/park_govern/blind_info', {
    params: {
      areaCode
    }
  })
}

export async function GetTopBlindInfo(areaCode?: string) {
  return request.get<API.TopBlindData[]>('/park_govern/blind_top', {
    params: {
      areaCode
    }
  })
}

export async function GetParkGovCountInfo(areaCode?: string) {
  return request.get<API.ParkGovCountData[]>('/park_govern/use_ratio/count_and_gap', {
    params: {
      areaCode
    }
  })
}

export async function GetParkGovUseInfo(areaCode?: string) {
  return request.get<API.ParkGovUseData>('/park_govern/use_ratio/by_time', {
    params: {
      areaCode
    }
  })
}

/**停车资源统计 */
export async function GetParkResourceStatic(areaCode?: string) {
  return request.get<API.GetParkResourceStatic>('resource/total-static', {
    params: {
      areaCode
    }
  })
}
/**停车经营统计 */
export async function GetincomesourceStatic(areaCode?: string,cityCode?: string) {
  return request.get('operate/static', {
    params: {
      areaCode,
      cityCode
    }
  })
}
/**停车监管* */
export async function GetSuperviseStatic(areaCode?: string) {
  return request.get('supervise/static', {
    params: {
      areaCode
    }
  })
}
export async function GetSuperviseTwoStatic(areaCode?: string) {
  return request.get('supervise/static-two', {
    params: {
      areaCode
    }
  })
}
/**停车难治理 */
export async function GetGrovernStatic(areaCode?: string) {
  return request.get('grovern/static', {
    params: {
      areaCode
    }
  })
}
/**套牌车列表 */
export async function GetPlatedeck( params:{
  keyword?: string
  pageNum?: string
  pageSize?: string
}) {
  return request.get('resource/get-plate-deck', {
    params
  })
}
/**敏感车首页 */
export async function GetPlatesensitive( params:{
  keyword?: string
  pageNum?: string
  pageSize?: string
}) {
  return request.get('resource/get-plate-sensitive', {
    params
  })
}
/**僵尸车辆列表 */
export async function GetPlatezombie( params:{
  keyword?: string
  pageNum?: string
  pageSize?: string
}) {
  return request.get('resource/get-plate-zombie', {
    params
  })
}
/**设备报警列表 */
export async function GetPlatetraffic( params:{
  keyword?: string
  pageNum?: string
  pageSize?: string
}) {
  return request.get('resource/traffic/page', {
    params
  })
}
/**设备报警列表 */
export async function Getroadirregularparking( params:{
  areaCode?: string
  keyword?: string
  pageNum?: string
  pageSize?: string
}) {
  return request.get('resource/road-irregular-parking', {
    params
  })
}
/*上报事件列表 */
export async function GetInspectorgReport( params:{
  areaCode?: string
  keyword?: string
  pageNum?: string
  pageSize?: string
}) {
  return request.get('resource/inspector/report/list', {
    params
  })
}
/*未知订单列表 */
export async function GetUnknownOrderlist( params:{
  areaCode?: string
  pageNum?: string
  pageSize?: string
}) {
  return request.get('resource/unknown/order/list', {
    params
  })
}
