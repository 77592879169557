import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Panel from '../index'
import style from './style.module.scss'
import cn from 'classnames'
import { Radar } from '@ant-design/charts'
import useZone from '@/services/data/useZone'
import { Spin } from 'antd'
import { GetRatioDataByType } from '@/services/api'
import { useRequest } from 'ahooks'
import ImgSource from '@/assets/img/resource/zhouzhuan.png'
import { number } from 'echarts'
export interface ConvertProps {
  areaCode?: string
}

const Convert: React.FC<ConvertProps> = (props) => {
  const [activeTab, changeTabIndex] = useState<string>("1")
  const[activeIndex,setActiveIndex]=useState<number>(0)
  //const { loading: TabLoading, data: TabData, error: ZoneError } = useZone()

  /*useEffect(() => {
    if (TabData?.success === true && TabLoading === false) {
      changeTabIndex(TabData.data[0].zoneId)
    }
  }, [TabData?.data, TabData?.success, TabLoading])
*/
//let  TabData=[{zoneName:'医院',zoneId:'100'},{zoneName:'行业名',zoneId:'100'},{zoneName:'住宅',zoneId:'101'},{zoneName:'商业',zoneId:'102'},{zoneName:'办公',zoneId:'103'},{zoneName:'学校',zoneId:'104'},{zoneName:'其它',zoneId:'105'}]
  const radarService = useCallback(() => {
    if (activeTab === undefined) return Promise.resolve(undefined)
    return GetRatioDataByType(props.areaCode)
  }, [activeTab, props.areaCode])

  const { data: ratioData, loading, error: RadarError } = useRequest(radarService, {
    refreshDeps: [radarService],
    formatResult: res => res,
  })


  const  TabList = useMemo(() => {
    if (ratioData?.data === undefined) return []
    return ratioData?.data.map(item=>{
      return {
        zoneName:item.industryName,zoneId:item.industryId
      }
    })
  },[ratioData])
  const radarData = useMemo(() => {
     
    if (ratioData?.data === undefined) return []
    return ['昨日', '今日最高路段', '今日平均', '今日最低路段', '节假日', '工作日'].map(type => {
      let key: (keyof API.ConverRatioData) | undefined = undefined
      console.log('key ',key,'type',type)
      switch (type) {
        case '昨日':
          key = 'yesterday'
          break
        case '今日最高路段':
          key = 'maxRoadSection'
          break
        case '今日平均':
          key = 'todayAverage'
          break
        case '今日最低路段':
          key = 'minRoadSection'
          break
        case '节假日':
          key = 'weekday'
          break
        case '工作日':
          key = 'workday'
          break
      }

      if (key && ratioData.data) {
        console.log('key ',key,'ratioData.data',ratioData.data[0],'ratioData.data[key]',ratioData.data[0])
        return {
          type,
          data: ratioData.data[activeIndex][key]
        }
      } else {
       
        return {
          type,
          data: 0
        }
      }
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ratioData])
  
  /*if (TabLoading) {
    return (
      <Panel title="行业周转率"   bagImg={ImgSource}>
        <div className="fetch_container" style={{ height: 278 }}>
          <Spin/>
        </div>
      </Panel>
    )
  }*/

  if (RadarError ) {
    return (
      <Panel title="行业周转率" >
        <div className="fetch_container" style={{ height: 278 }}>
          请求错误
        </div>
      </Panel>
    )
  }

  return (
    <Panel title="行业周转率 "  >
      <div className={style.container}>
        <div className={style.tabs}>
          {
            TabList.map((i,index) =>
              <div
                className={cn(style.item, {
                  [style.active]: activeTab === i.zoneId
                })}
                key={`tab_${i.zoneId}`}
                onClick={() => {
                  changeTabIndex(i.zoneId) 
                  setActiveIndex(index)
                }}
              ><p>{i.zoneName}</p></div>
            )
          }
        </div>
        <div className={style.box}>
          {
            loading ?
              <div className="fetch_container" style={{ height: 200 }}>
                <Spin/>
              </div> :
              <Radar
                data={radarData}
                xField="type"
                yField="data"
                padding={[40,10,40,0]}
                height={200}
                xAxis={{
                  line: null,
                  tickLine: null,
                  label: {
                    formatter(text: string, item: any, index: number) {
                      return `${text}: ${radarData[index].data}`
                    },
                    style: {
                      fill: '#ffffff'
                    }
                  },
                  grid: {
                    line: {
                      style: {
                        lineDash: null,
                      },
                    },
                  },
                }}
                yAxis={{
                  grid: {
                    line: {
                      type: 'line',
                      style: {
                        lineDash: null,
                        stroke: '#7BD2FF'
                      },
                    },
                    // alternateColor: 'rgba(123, 210, 255, 0.3)',
                  },
                  label: null
                }}
                area={{
                  style: {
                    fillOpacity: 0.85
                  }
                }}
                tooltip={{
                  crosshairs: {
                    line: {
                      style: {
                        stroke: '#ffffff'
                      }
                    }
                  }
                }}
              />
          }
        </div>
      </div>
    </Panel>
  )
}

export default Convert