import React, { useCallback } from 'react';
import { useRequest } from 'ahooks';
import Panel from '../index';
import CustomTable from '@/components/CustomTable';
import { Spin } from 'antd';
import { GetParkGapInfo } from '@/services/api';

export interface ParkingShortProps {
  areaCode?: string;
  maxHeight?: number;
}

const ParkingShort:React.FC<ParkingShortProps> = props => {
  const {maxHeight = 270, areaCode} = props;

  const { data = [], loading, error } = useRequest(useCallback(() => {
    return GetParkGapInfo(areaCode);
  },[areaCode]), {
    formatResult: res => res.data.map((item,index) => ({
      ...item,
      index
    })),
    refreshDeps: [areaCode]
  })

  if(loading) {
    return (
      <Panel title="停车缺口">
        <div className="fetch_container" style={{ height: 270 }}>
          <Spin/>
        </div>
      </Panel>
    )
  }

  if(error) {
    return (
      <Panel title="停车缺口">
        <div className="fetch_container" style={{ height: 270 }}>
          请求错误
        </div>
      </Panel>
    )
  }
  return (
    <Panel
      title="停车缺口"
    >
      <CustomTable
        dataSource={data}
        style={{height: `${maxHeight}px`}}
        rowKey="index"
        columns={[
          { title: '网格', dataIndex: 'name' },
          { title: '停车缺口', dataIndex: 'gapCount' },
          { title: '调整数', dataIndex: 'adjustCount' }
        ]}
        scroll={{y: maxHeight - 46}}
      />
    </Panel>
  )
}

export default ParkingShort;


