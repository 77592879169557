import React, { useCallback, useMemo, useEffect, useState } from 'react';
import { useRequest, useInterval } from 'ahooks';
import Panel from '../index';
import CustomTable from '@/components/CustomTable';
import { Spin, Button, Input } from 'antd';
import { GetIllegalPark } from '@/services/api';
import styles from './index.module.scss';
import Abutton from '@/components/Abutton'
import ImgSource from '@/assets/img/jianguan/unbound.png'
/** 转地址 */
const iframeURL = "http://lz.dgjx0769.com/lz/#/UrbanIntegration/EventManage/InspectionReport";
export interface ParkInTableProps {
  areaCode?: string;
  showPicture: (src: string, extraData?: object) => void;
  onTabelItemUrlChange:(src:string)=>void;
}

const $ = (selector: string) => document.querySelector(selector);

const ParkInTable:React.FC<ParkInTableProps> = ({areaCode,showPicture,onTabelItemUrlChange}) => {
  const [scrollInterval, setScrollInterval] = useState<number | null>(null);
  const [allowRefresh,setAllowRefresh] = useState(true);
  const [searchValue,setSearchValue] = useState<string>();

  const { data = [], refresh, loading,  error } = useRequest(useCallback(() => {
    return GetIllegalPark(areaCode,'15');
  },[areaCode]), {
    formatResult: res => res.data,
    refreshDeps: [areaCode]
  })
console.log('违停情况',data)
  const tableData = useMemo(() => {
    if(!searchValue) return data;
    return data.filter(item => item.plateNo.indexOf(searchValue) != -1);
  },[data,searchValue])

  const SearchInputMemo = useMemo(() => {
    return <Input.Search
      className={styles.search_input}
      onSearch={(value) => setSearchValue(value)}
      placeholder="搜索车牌"
      enterButton={<img src="/img/icon_search_white.png" alt=""/>}
    />
  }, [setSearchValue])

  const AbuttonBox = useMemo(() => {
    return <Abutton  iframeURL={iframeURL} text={''}/>
  }, [])

  const scrollContainer = useCallback(() => {
    const container = $('.parks-in-table .ant-table-body');
    if (container !== null) {
      const { clientHeight, scrollHeight, scrollTop } = container;
      let height = scrollTop + 1;
      const top = scrollHeight - clientHeight - height;
      if (top <= 0) {
        height = 0
      }

      if (scrollHeight <= clientHeight) {
        setScrollInterval(null)
      }
      
      container.scrollTo(0,height)
    }
  }, [])

  const stopTick = useCallback(() => {
    setAllowRefresh(false);
    setScrollInterval(null)
  }, [])
  
  const startTick = useCallback(() => {
    setAllowRefresh(true);
    setScrollInterval(100)
  }, [])

  useInterval(() => {
    scrollContainer()
  }, scrollInterval)

  useInterval(() => {
    refresh();
  },allowRefresh ? 76000 : null)

  useEffect(() => {
    const container = $('.parks-in-table .ant-table-body')
    if (container) {
      const { clientHeight, scrollHeight } = container;
      const isOver = scrollHeight > clientHeight
      if (isOver) {
        setScrollInterval(100)
      }
    }
  }, [tableData])

  if (loading) {
    return (
      <Panel
        title="违停情况"

      >
        <div className="fetch_container" style={{ height: 200 }}>
          <Spin/>
        </div>
      </Panel>
    )
  }

  if (error) {
    return (
      <Panel title="违停情况" >
        <div className="fetch_container" style={{ height: 200 }}>
          请求错误
        </div>
      </Panel>
    )
  }

  return (
    <Panel
      title="违停情况"
 
    >
      <div onMouseEnter={stopTick} onMouseLeave={startTick}>
        <CustomTable
          className="parks-in-table"
          dataSource={tableData}
          rowKey={'index'}
          style={{height: '196px'}}
          columns={[          
            { title: '车牌号', width: 89, dataIndex: 'plateNo' },
            { title: '车主信息', width: 67, dataIndex: 'mobile',render:(text,record)=>(
              record.mobile&&record.mobile.slice(0,7)+`****`
              ) },
            { title: '路段', width: 90, dataIndex: 'roadSectionName', className: 'text-ellipsis' },
          
            { title: '违停取证', width: 80, render: (item,index) => (
              item.photo != '' && item.photo != null && <Button type="text" onClick={() => showPicture(item.photo)}><img src="/img/icon_camera_2.png" alt=""/></Button>
            ) }
          ]}
          scroll={{ y: 156 }}
        />
      </div>
    </Panel>
  )
}

export default ParkInTable;