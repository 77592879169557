import React from 'react';
import { Modal } from 'antd';
import styles from './index.module.scss';

const CarParkWindow:React.FC<{
  visible: boolean;
  onCancel: () => void;
  onShowPicture: (src: string) => void;
  data: {
    orderNo?: string;
    orderStatusLabel?: string;
    billDate?: string;
    driveOutDate?: string;
    parkTime?: string;
    plateNo?: string;
    photoAddr?: string;
  }
}> = ({visible,onCancel,onShowPicture,data}) => {

  const showPicture =  () => {
    onCancel();
    onShowPicture(data.photoAddr || '');
  }
  return (
    <Modal
      className={styles.street_view}
      width={260}
      footer={null}
      visible={visible}
      onCancel={onCancel}
      closeIcon={<img src="/img/icon_close_circle.png" alt="" className={styles.window_close} />}
      maskStyle={{background: 'transparent'}}
    >
      <div className={styles.street_view_info}>订单号：{data.orderNo}</div>
      <div className={styles.street_view_info}>订单状态：{data.orderStatusLabel}</div>
      <div className={styles.street_view_info}>订单开始时间：{data.billDate}</div>
      <div className={styles.street_view_info}>订单结束时间：{data.driveOutDate}</div>
      <div className={styles.street_view_info}>停车时长：{data.parkTime}</div>
      <div className={styles.street_view_car}>
        <div>车辆牌照：{data.plateNo}</div>
        <img src="/img/icon_camera.png" alt="" onClick={showPicture} />
      </div>
    </Modal>
  )
}

export default CarParkWindow;