import React from 'react';
import { ConfigProvider,Empty,Table } from 'antd';
import { TableProps } from 'antd/lib/table';
import styles from './index.module.scss';
const customizeRenderEmpty = () => (
  <Empty
      image={Empty.PRESENTED_IMAGE_SIMPLE} 
      description={
          <span className={styles.nodata}>
            暂无数据
          </span>
        }
      />
);
/*
rowClassName = {(record, index) => {
    let className = index % 2 ? 'shallow_gray': 'deep_gray';
    return className
}}*/
const TopTable:React.FC<TableProps<any>> = (props) => {
  return <ConfigProvider renderEmpty={customizeRenderEmpty}><Table   { ...props}  className={`${styles.custom_table} ${props.className}`} pagination={false} 
   
  />
  </ConfigProvider>
}

export default TopTable;