import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useRequest, useInterval } from 'ahooks';
import Panel from '../index';
import ZoneSelect from '@/components/ZoneSelect';
import CustomTable from '@/components/CustomTable';
import { Spin } from 'antd';
import { GetunPaidCar } from '@/services/api';
import styles from './index.module.scss';
import ImgSource from '@/assets/img/jianguan/qianfeicheliang@2x.png'
import Abutton from '@/components/Abutton'
/** 转地址 */
const iframeURL = "http://lz.dgjx0769.com/lz/#/UrbanIntegration/BasicDataManage/CarManage";
export interface KeyCarTableProps {
  areaCode?: string;
}

const $ = (selector: string) => document.querySelector(selector);

const KeyCarTable:React.FC<KeyCarTableProps> = ({areaCode}) => {
  const [scrollInterval, setScrollInterval] = useState<number | null>(null);
  const [allowRefresh,setAllowRefresh] = useState(true);

  const { data = [], loading, error, refresh } = useRequest(useCallback(() => {
    return GetunPaidCar(areaCode,'15')
  },[areaCode]), {
    formatResult: res => res.data.map((item,index) => ({...item, index})),
    refreshDeps: [areaCode]
  })

  const scrollContainer = useCallback(() => {
    const container = $('.KeyCar-table .ant-table-body');
    if (container !== null) {
      const { clientHeight, scrollHeight, scrollTop } = container;
      let height = scrollTop + 1;
      const top = scrollHeight - clientHeight - height;
      if (top <= 0) {
        height = 0
      }

      if (scrollHeight <= clientHeight) {
        setScrollInterval(null)
      }
      
      container.scrollTo(0,height)
    }
  }, [])

  const stopTick = useCallback(() => {
    setAllowRefresh(false);
    setScrollInterval(null)
  }, [])
  
  const startTick = useCallback(() => {
    setAllowRefresh(true);
    setScrollInterval(100)
  }, [])

  useInterval(() => {
    scrollContainer()
  }, scrollInterval)

  useInterval(() => {
  //  refresh();
  },allowRefresh ? 76000 : null)

  useEffect(() => {
    const container = $('.KeyCar-table .ant-table-body')
    if (container) {
      const { clientHeight, scrollHeight } = container;
      const isOver = scrollHeight > clientHeight
      if (isOver) {
        setScrollInterval(100)
      }
    }
  }, [data])
  
  const AbuttonBox = useMemo(() => {
    return <Abutton iframeURL={iframeURL} text={''} />
  }, [])

  if(loading) {
    return (
      <Panel
        title="欠费车辆" 
      >
        <div className="fetch_container" style={{ height: 200 }}>
          <Spin/>
        </div>
      </Panel>
    )
  }

  if(error) {
    return (
      <Panel
        title="欠费车辆"
      >
        <div className="fetch_container" style={{ height: 200 }}>
          请求错误
        </div>
      </Panel>
    )
  }

  return (
    <Panel
      title="欠费车辆" 
    >
       <div onMouseEnter={stopTick} onMouseLeave={startTick}>
       <div>
        <CustomTable
          className={`${styles.car_table} KeyCar-table`}
          dataSource={data}
          rowKey={'index'}
          style={{height: '196px'}}
          columns={[
            { title: '车主手机号', dataIndex: 'mobile',render:(text,record)=>`${record.mobile?record.mobile.slice(0,7)+`****`:''}` },
            { title: '车牌号', dataIndex: 'plateNo' },
            { title: '欠费金额',  dataIndex: 'unPayAmount' },
          ]}
          scroll={{ y: 156 }}
        />
      </div>
      </div>
    </Panel>
  )
}

export default KeyCarTable;