/* eslint-disable react/jsx-no-target-blank */
import React, { useState } from 'react';
import styles from './index.module.scss';

const menus = ['停车资源','停车经营','停车监管','停车难治理']
const iframeURL = "https://liu.web.dgjx0769.com/cloud-table/#/monitoringSeat";
const iframeURLtest = "https://web.dgjx0769.com/cloud-table/#/";
const BottomMenu:React.FC<{
  onMenuChange: (val: number) => void;
  onOpenService: () => void;
  menu: number;
}> = ({onMenuChange,menu,onOpenService}) => {
  const menuChange = (index: number) => {
    onMenuChange(index);
  }

  return (
    <div className={styles.bottom_menu}>
      {
        menus.map((item,index) => (
          <button
            key={index}
            className={`${styles.menu_item} ${menu === index ? styles.menu_item_selected : ''}`}
            onClick={() => menuChange(index)}
          >{item}</button>
        ))
      }
      <button className={styles.menu_item}>
       <a href={iframeURL}  target="_blank">云坐席服务</a> 
        </button>
    </div>
  )
}

export default BottomMenu;