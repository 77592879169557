import { GetDifficultParkOrRoad ,GetDifficultRoadOrRoad} from '@/services/api'
import {  useInterval,useRequest } from 'ahooks'
import { Spin,Carousel ,Empty} from 'antd'
import ReactDOM from 'react-dom';
import React, { useCallback,useEffect,useRef, useMemo , useState} from 'react'
import Panel from '../index'
import style from './style.module.scss'
import ParkingAnimationIcon from '@/components/ParkingAnimationIcon'
import ImgSource from '@/assets/img/resource/tingche.png'
import AlarmModal, { OnAlarmClick, AlarmType } from '@/components/AlarmModal';
export interface BusyAreaProps {
  areaCode?: string;
  onOpenWorkbill: () => void;
  onAlarmFocus: (roadSectionId: string,parkingNo: string) => void;
}


const BusyArea: React.FC<BusyAreaProps> = (props) => {
   const [interval, changeInterval] = useState<number | null>(null)
   const [alarmModal,setAlarmModal] = useState(false);
   const [lotchangModal,setLotChangModal] = useState(true);
   const [tabNoModal,setTabNoModal] = useState(1);
   const alarmRef = useRef<{
     onAlarmClick: OnAlarmClick
   }>(null);
   console.log('停车难',props.areaCode)
 
  const { data:fetchData=[] , loading, error, refresh} = useRequest(useCallback(() => {
    return GetDifficultParkOrRoad({
      areaCode:props.areaCode,
      limit:'15'
    })},[props.areaCode]), {
    formatResult: res => {
      if(res.data){
        console.log('停车难',res.data)
        // eslint-disable-next-line array-callback-return
        let data= res.data.filter((item:object,index:number)=>{
          if(index<15){
             return item
           }
         })
         return data
        }
        return res.data
    },
    refreshDeps: [props.areaCode]
  })
  const { data: roadData=[]} = useRequest(useCallback(() => {
    return GetDifficultRoadOrRoad({
      areaCode:props.areaCode,
      limit:'15'
    })},[props.areaCode]), {
      formatResult: res =>res.data,
      refreshDeps: [props.areaCode]
    // formatResult: res => {
    //   if(res.data){
    //     // eslint-disable-next-line array-callback-return
    //     let data= res.data.filter((item:object,index:number)=>{
    //       if(index<15){
    //          return item
    //        }
    //      })
    //      return data
    //     }
    //     return res.data
    // }
  })

  function group(array:[], subGroupLength:number) {
    let index = 0;
    let newArray = [];
    while(index < array.length) {
        newArray.push(array.slice(index, index += subGroupLength));
    }
    return newArray;
}

//路段难
 let roadgroupedArray =roadData? group(roadData, 5):[];
 let roadnewData= roadgroupedArray.map((item,index:number)=>{
  return [item.slice(0, 2), item.slice(2, 5)]
 })
//停车场
let fetchgroupedArray = fetchData?group(fetchData, 5):[];
let fetchnewData= fetchgroupedArray.map((item,index:number)=>{
 return [item.slice(0, 2), item.slice(2, 5)]
})

//切换
let data=!lotchangModal?fetchnewData:roadnewData
 const lotchangdata =  (val: boolean)=> {
  setLotChangModal(val);
  setTabNoModal(val?1:0)
}
 useInterval(() => {
  //console.log(data)
  },10000)

  const showIframe = () => {
    setAlarmModal(false);
    props.onOpenWorkbill();
  }

  const showAlarm = (alarmType: number) => {
    setAlarmModal(true);
    alarmRef.current?.onAlarmClick(alarmType as AlarmType);
  }
  
  
    
  
  if (loading) {
    return (
      <Panel title="停车难路段/停车场"  >
        <div className="fetch_container" style={{ height: 206 }}>
          <Spin/>
        </div>
      </Panel>
    )
  }

  if (error) {
    return (
      <Panel title="停车难路段/停车场" >
        <div className="fetch_container" style={{ height: 206 }}>
        请求错误
        </div>
      </Panel>
    )
  }


  return (
    <Panel title="停车难路段/停车场" >
      
        
      
        <div className={style.parkinglotttag} onClick={()=>lotchangdata(!lotchangModal)}> 切换</div>
        <div className={style.parkinglotmore}  onClick={()=>showAlarm(8)}> 更多</div>
      {data.length>0
      ?
      <div  style={{ height: 206 }}>
        <Carousel autoplay autoplaySpeed={10000} swipe swipeToSlide touchMove arrows>
       {
       data.map((page, pageindex) => { 
        return (
            <div key={`page_${pageindex}`}  className={style.container}>
              {
                page.map((group, groupIndex) => {
                  return (
                    <div key={`group_${groupIndex}`} className={style.row}>
                      {group.map((item:object,index:number) => {
                        return (
                          <div key={`parking_${index}`} className={style.parking_item}>
                      
                            <div className={style.label}>{item['roadSectionName']||item['parkName'] }</div>
                            <ParkingAnimationIcon/>
                            <div className={style.tips}>
                              <div>利用率: {item['useRate']}%</div>
                              {/* <div>一小时到达: {item['oneHourArrive']}</div> */}
                            </div>
                          </div>
                        )
                      }) }
                    </div>
                  )
                })
              }
            </div>
          )
        })}
        </Carousel>
        </div>
        :
        <div className="fetch_container" style={{ height: 206 }}>
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE} 
          description={
              <span className={style.nodata}>
                暂无数据
              </span>
            }
          />
        </div>
        }
        <AlarmModal
        areaCode={props.areaCode}
        ref={alarmRef}
        visible={alarmModal}
        onShowIframe={showIframe}
        onClose={() => setAlarmModal(false)}
        onAlarmFocus={props.onAlarmFocus}
        tabNo={tabNoModal}
      />
    </Panel>
  )
}

export default BusyArea