/** 停车资源分布 */
import { GetDistributeData} from '@/services/api'
import { useRequest } from 'ahooks'
import { Spin } from 'antd'
import ReactEcharts from 'echarts-for-react';
import React, { useCallback, useState,useMemo, useEffect } from 'react'
import Panel from '../index'
import styles from './style.module.scss'
import { Pie } from '@ant-design/charts';
import { autorun } from 'mobx'
import ImgSource from '@/assets/img/resource/resource.png'
import Abutton from '@/components/Abutton'
export interface ParkikngResourceProps {
  areaCode?: string
}
/** 统计首页跳转地址 */
const iframeURL ="http://lz.dgjx0769.com/lz/#/UrbanIntegration/BasicDataManage/BasicData";
const ParkikngResource: React.FC<ParkikngResourceProps> = (props) => {
    const [ setData] = useState({});
 
  const service = useCallback(() => {
    return GetDistributeData(  props.areaCode )
  },[props.areaCode])

  const { loading, data, error } = useRequest(service, {
    formatResult: res => res.data
  })

  if (error && loading === false) {
    <Panel
      title="停车资源分布"
    >
      <div className="fetch_container" style={{ height: 89 }}>
        请求错误
      </div>
    </Panel>
  }
  const showData =data?data:[];
  // eslint-disable-next-line array-callback-return
  let piedata1= data?.filter((item,index)=>{
    if(item.type=='收入'){
      item.name=item.name+item.colorType
      return item
    }
  })
  // eslint-disable-next-line array-callback-return
  let piedata2= data?.filter((item,index)=>{
    if(item.type=='车位'){
   item.name=item.name+item.colorType
      return item
    }
  })

/**图标数据 */
const AbuttonBox = useMemo(() => {
  return <Abutton  iframeURL={iframeURL} text={''}/>
}, [])

let Option = {
  tooltip: {
    trigger: 'item',
    formatter: '{b}: {c} ({d}%)'
  },

  series: [
    {
      
      center: ['50%', '60%'],
      type: 'pie',
      selectedMode: 'single',
      radius: [0, '30%'],
      color:['#00ccff','#6772ff','#008aff'],
      label: {
        color: 'rgba(32, 188, 252, 1)',
        //formatter: '{b}: {c}'
      },
      avoidLabelOverlap:true,
      labelLine: {
        length: 40
      },
      
      data:piedata2
    },
    {
     
      type: 'pie',
      radius: ['40%', '55%'],
      center: ['50%', '60%'],
      labelLine: {
        length: 20,
        length2:5
      },
      avoidLabelOverlap:true,
      label: {
        color: 'rgba(32, 188, 252, 1)',
      },
      color:['#2dbcdf','#6275d5','#4aa3e2'],
      data:piedata1
    }
  ]
};

  return (
    <Panel
      title="停车资源分布"
    >
           
           {
        loading ?
          <div className="fetch_container" style={{ height: 120 }}>
            <Spin/>
          </div> :
          <ReactEcharts
          option={Option}
          style={{height: '240px', width: '420px'}}
          className='react_for_echarts' 
          />

      }
    </Panel>
  )
}

export default ParkikngResource