/** 停车收入统计数据 */
import { GetincomesourceStatic } from '@/services/api'
import { useRequest } from 'ahooks'
import { Spin } from 'antd'
import React, { useCallback,useMemo, useState, useEffect } from 'react'
import Panel from '@/components/Panel'
import { Pie } from '@ant-design/charts';
import ImgSource from '@/assets/img/panel_bg.png'
import Abutton from '@/components/Abutton'
import { color } from 'echarts'
/** 转地址 */
const iframeURL = "http://lz.dgjx0769.com/lz/#/PaymentSettlement/HomePage/FinancialChartAnalysisPandect";
export interface ParkingIncomeProps {
  areaCode?: string
}
const bool =false;

const ParkingIncome: React.FC<ParkingIncomeProps> = (props) => {
    const [ setData] = useState({});

    
    const { data = [], loading, error } = useRequest(useCallback(() => {
    return GetincomesourceStatic(props.areaCode,'450200');
  },[props.areaCode]), {
    formatResult: res => res.data,
    refreshDeps: [props.areaCode]
  })
    if (error && loading === false) {
      <Panel
        title=""
       
      >
        <div className="fetch_container" style={{ height: 89 }}>
          请求错误
        </div>
      </Panel>
    }
    const AbuttonBox = useMemo(() => {
      return <Abutton iframeURL={iframeURL} text={''} />
    }, [])
    
/**图标数据  ['#9E5FF8', '#00B4FF', '#00EACB'], */
var demoData = [
  {
    type: '接入车位收入',
    value: data?.joinIncome||0,
  },
  {
    type: '增值业务收入',
    value: data?.addIncome||0,
  },
  {
    type: '自营车位收入',
    value: data?.ownIncome||0,
  }

];

const showData =null;
  return (
    <Panel
      title="停车收入"  renderLeft={AbuttonBox}
    >
           
           {
        loading ?
          <div className="fetch_container" id="park_income" style={{ height: 200,width:420 }}>
            <Spin/>
          </div> :
          <Pie
          appendPadding= {10}
          data= {demoData}
          angleField= 'value'
          colorField= 'type'
          radius= {0.9}
          innerRadius={ 0.5}
          color={['#9E5FF8', '#00B4FF', '#00EACB']}
         /// animate={true}
          width={420}
          height={200}
           legend={{
            layout: 'horizontal',
            position: 'bottom',
            itemName:{style:{
              fill:'#ffffff'
            }}
           }}// 关闭图例
           //动画
           animation= {{
            appear: {
              animation: 'grow-in-xy',
              duration: 5000,
            },
            enter: {
              animation: 'grow-in-xy',
              duration: 3000,
            }
            
        
          }}
         meta= {{
            value: {
              formatter: function formatter(v: string) {
                return '';
              },
            },
          }}
          label={ {
            type: 'spider',
            // offset: 20,
            labelHeight: 5,
            style: { fill:'white',fontSize:16},
            content: '{percentage}',
          }}
          interactions={ [
            { type: 'element-selected' },
            { type: 'element-active' },
            { type: 'pie-statistic-active' },
          ]}
          statistic={ {
            title:{
              formatter: function formatter() {
                return '';
              },
            },
            content :{
              style: {
                fill: 'white',fontSize: '20',
                textAlign: 'center',
              },
            }
          }}
          />
      }
     
    </Panel>

  )
}

export default ParkingIncome