import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useRequest, useInterval } from 'ahooks';
import Panel from '../index';
import ZoneSelect from '@/components/ZoneSelect';
import CustomTable from '@/components/CustomTable';
import { Spin, Button, Input } from 'antd';
import { GetCarBreakData } from '@/services/api';
import styles from './index.module.scss';
import imgSource from '@/assets/img/jianguan/abnormal_vehicle.png'
import Abutton from '@/components/Abutton'
/** 转地址 */
const iframeURL = "http://lz.dgjx0769.com/lz/#/UrbanIntegration/EventManage/InspectionReport";
export interface CarTableProps {
  areaCode?: string;
  showPicture: (src: string, extraData?: object) => void;
}

const $ = (selector: string) => document.querySelector(selector);
const TabData =[{zoneName:"僵尸车",value:"zombie"},{zoneName:"敏感车",value:"sensitive"},{zoneName:"套牌车",value:"fake"},]
const CarTable:React.FC<CarTableProps> = ({areaCode,showPicture}) => {
  
  const [zoneId,setZoneId] = useState('');
  const [scrollInterval, setScrollInterval] = useState<number | null>(null);
  const [allowRefresh,setAllowRefresh] = useState(true);

  const { data , loading, error, refresh } = useRequest(useCallback(() => {
    return GetCarBreakData( areaCode,'15')
  },[areaCode]), {
    formatResult: res => res.data,
    refreshDeps: [areaCode]
  })



 
  const scrollContainer = useCallback(() => {
    const container = $('.active-car-table .ant-table-body');
    if (container !== null) {
      const { clientHeight, scrollHeight, scrollTop } = container;
      let height = scrollTop + 1;
      const top = scrollHeight - clientHeight - height;
      if (top <= 0) {
        height = 0
      }

      if (scrollHeight <= clientHeight) {
        setScrollInterval(null)
      }
      
      container.scrollTo(0,height)
    }
  }, [])

  const stopTick = useCallback(() => {
    setAllowRefresh(false);
    setScrollInterval(null)
  }, [])
  
  const startTick = useCallback(() => {
    setAllowRefresh(true);
    setScrollInterval(100)
  }, [])

  useInterval(() => {
    scrollContainer()
  }, scrollInterval)

  useInterval(() => {
    //refresh();
  },allowRefresh ? 76000 : null)

  useEffect(() => {
    const container = $('.active-car-table .ant-table-body')
    if (container) {
      const { clientHeight, scrollHeight } = container;
      const isOver = scrollHeight > clientHeight
      if (isOver) {
        setScrollInterval(100)
      }
    }
  }, [data])

 
  if(loading) {
    return (
      <Panel
        title="套牌车辆" 
        
      >
        <div className="fetch_container" style={{ height: 196 }}>
          <Spin/>
        </div>
      </Panel>
    )
  }

  if(error) {
    return (
      <Panel
        title="套牌车辆" 
       
      >
        <div className="fetch_container" style={{ height: 196 }}>
          请求错误
        </div>
      </Panel>
    )
  }



  return (
    <Panel
      title="套牌车辆" 
    >
     <div onMouseEnter={stopTick} onMouseLeave={startTick}>
       <div>
        <CustomTable
          className={`${styles.car_table} active-car-table`}
          dataSource={data}
          rowKey={'index'}
          style={{height: '196px'}}
          columns={[
            { title: '车牌号', width: 124, dataIndex: 'plateNo' },
            { title: '路段', width: 88, dataIndex: 'roadSectionName' },
            { title: '车辆1照片', width: 95,render: (item,index) => (
              item.driveInPhoto1 && <Button type="text" onClick={() => showPicture(item.driveInPhoto1)}><img src="/img/icon_camera_2.png" alt=""/></Button>
             
            ) },
            { title: '车辆2照片', width: 75, render: (item,index) => (
              item.driveInPhoto2 && <Button type="text" onClick={() => showPicture(item.driveInPhoto2)}><img src="/img/icon_camera_2.png" alt=""/></Button>
             
            ) }
           
          ]}
           scroll={{ y: 156 }}
        />
      </div>
      </div>
    </Panel>
  )
}

export default CarTable;