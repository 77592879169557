/// <reference path="./API.d.ts" />
import request from './request2';


  export async function getverifyCode() {
    return request.get('fss-auth/p-api/v1/verify-code/data');
  }
//登录

export async function login(params: {
  username?: string;
  password?: string;
  verifyCode?: object;
}) {
  return request.post('fss-auth/p-api/v1/admin/login',params )
}
