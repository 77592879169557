/* eslint-disable jsx-a11y/alt-text */
import React from 'react'
import styles from './style.module.scss'
import ImgSource from '@/assets/img/panel_bg.png'
export interface PanelProps {
  title?: string
  bagImg?:string
  height?:number
  renderRight?: JSX.Element
  renderLeft?:JSX.Element
  headClass?: string;
}

const Panel: React.FC<PanelProps> = (props) => {
  const { title = '面板标题',bagImg='' } = props
  return (
    <div className= {styles.panel} style={{ height: props.height? props.height:'100%' }}>
      {bagImg ? 
      <div className={styles.abs_render_box}>
        {/* <div className={styles.abs_render_left}>{props.renderLeft}</div>  */}
       {/* <div className={styles.abs_render_right}>{props.renderRight}</div>  */}
       </div>
      : <div className={`${styles.head} ${props.headClass} ${styles.head_bg}`}>
        <i className={styles.bg_left}></i> 
        <i className={styles.bg_right}></i>
        <div className={styles.content}>
          {title}
        </div>
        <div>{props.renderRight}</div>
      </div> 
      
      }
        
    {bagImg ? <img src={bagImg} className={styles.imgClass} style={{ height: props.height? props.height:'100%' }}></img>:<img src={ImgSource} className={styles.imgClass} style={{ height: props.height? props.height:'100%' }}></img>}
      <div className={styles.tableClass}>
        {props.children}
      </div>
    </div>
  )
}

export default Panel