import React, { useState, useEffect, useCallback, forwardRef, useImperativeHandle } from 'react';
import { useRequest, useInterval } from 'ahooks';
import { GetCityInfo, GetCityWeather,GetCityAreaInfo } from '@/services/api';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Link,
  useHistory
} from 'react-router-dom';
import Select from '../Select';
import Moment from 'moment';
import styles from './index.module.scss';
import Search from '@/components/Search';
import Login from '@/components/Login';

export type SetareaCode = (val: string) => void;

export type SetAreaCode = (val: string) => void;

const fullScreenMatch = matchMedia('all and (display-mode: fullscreen)');

const Header:React.FC<{
  onCityChange: (city: {
    areaCode:string,
    coordinates: [number,number];
  }) => void;
  onScreenChange?: () => void;
  screenWide?: boolean;
  ref: any;
}> = forwardRef(({onCityChange, onScreenChange, screenWide},ref) => {
  const [fullScreen,setFullScreen] = useState(fullScreenMatch.matches);
  const [time,setTime] = useState(Moment());
  const [cityCode,setCityCode] = useState('');
  const[areaCode,setAreaCode]=useState('')
  const[getCityName,setgetCityName]=useState('柳州市')
  const {data: cities = []} = useRequest(GetCityInfo,{
    formatResult: res => res.data.map(item => ({
      label: item.cityName,
      value: item.areaCode,
      coordinates: [Number(item.lng),Number(item.lat)]
    }))
  });
  const onSearch = (keyword:string, searchType: number) => {
   
  }

  const{data:areaDataList=[] }=useRequest(useCallback(()=>{
return GetCityAreaInfo('450200')
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },['450200']),{
    formatResult: res =>{
      const citydata={
        label:'柳州市',
        value:'',
        coordinates: [Number('109.44626'),Number('24.33941')] //116.397128  39.916527
      }
     let newdata= res.data.map(item => ({
        label: item.areaName,
        value: item.areaCode,
        coordinates: [Number(item.lng),Number(item.lat)]
     }))
     newdata.unshift(citydata)
     return newdata
    }
  })
  // const { data: weather } = useRequest(useCallback(() => {
  //   return GetCityWeather(areaCode);
  // },[areaCode]), {
  //   refreshDeps: [areaCode]
  // })

  const times=Moment().format('l').replace(/\//g,'-')   
  const days= Moment().format('dddd')
  const week= Moment().format('LT');
  const cityChange = (value:string) => {
    const data = areaDataList.find(item => item.value === value);
    setAreaCode(value);
    setgetCityName(data?.label as string)
    onCityChange({
      areaCode:data?.value as string,
      coordinates: data?.coordinates as [number,number]
    });
  }

  const fullScreenChange  = () => {
    if(fullScreenMatch.matches) {
      document.exitFullscreen();
    }else {
      document.documentElement.requestFullscreen();
    }

  }
  useInterval(() => {
    //setTime(Moment());
  },1000)

  useEffect(() => {
    // const query = matchMedia('all and (display-mode: fullscreen)');
    fullScreenMatch.onchange = () => {
      setFullScreen(fullScreenMatch.matches);
    }
  })
  useImperativeHandle(ref, () => ({
    setAreaCode
  }))
  return (
    <div className={`${styles.header} ${styles.header_sm} ${screenWide ? styles.header_wide : ''}`}>
       {/* 时间 */}
        <div  className={`${styles.times_bg}`}>
          <div  className={`${styles.times_box}`}>
            {times}
           <em style={{margin:'0 8px'}}></em>
           {days}
           <em style={{margin:'0 8px'}}></em>
           {week}
           </div>
        </div>  
        {/* 时间 */}
        <div  className={`${styles.system_bg}`} onClick={() => {window.open('https://liu.web.dgjx0769.com/ims-operating/#/login?redirect=%2F')}}>
          <div className={`${styles.system_box}`} >系统模块 </div>
        </div>
        {/* 标题 */}
        <div  className={`${styles.maptitle_box}`}>
          <div className={`${styles.maptitle_bg}`}></div>
          {/* <div className={`${styles.maptitle}`} >{`${getCityName}`}静态交通大数据可视化平台</div> */}
          <div className={`${styles.maptitle}`} >柳州市静态交通大数据可视化平台</div>
        </div>
       
        <div className={`${styles.btn_diamond}`}>
        <Select
          className={styles.city_select}
          dropdownMatchSelectWidth={182}
          options={
            areaDataList
          }
          value={areaCode}
          onChange={cityChange}
        />
      </div>
      <div className={`${styles.search_bg}`}>
      {  <Search onSearch={onSearch} /> }
      </div>
     

    </div>
  )
})

export default Header;