import { makeAutoObservable } from 'mobx'
class Usersinfo {
  token?: string 
  userId?:string 
  userName?:string
  info?: {}
  constructor() {
    makeAutoObservable(this)
  }
}

const userStore = new Usersinfo()

export default userStore
