import React, { useCallback, useMemo, useState } from 'react';
import { useRequest } from 'ahooks';
import Panel from '../index';
import { RadialBar } from '@ant-design/charts';
import { Spin } from 'antd';
import { GetGrovernUseRateFourData, GetGrovernUseRateOneData, GetGrovernUseRateThreeData, GetGrovernUseRateTwoData,
  GetGrovernParkUseRateFourData, GetGrovernParkUseRateOneData,GetGrovernParkUseRateTwoData,GetGrovernParkUseRateThreeData} from '@/services/api';
import cn from 'classnames'
import styles from './index.module.scss';
import ImgSource from '@/assets/img/tingchenan/tingcheliyonglv.png'
export interface ParkingUsageProps {
  areaCode?: string;
}

const tabs = ['车行道车位','人行道车位']

const colors = ['#FEC849', '#315AFA', '#00A8FE', '#66CE97', '#3E77D4'];
const KeyCarTable: React.FC<ParkingUsageProps> = ({ areaCode }) => {
  const [activeTab, changeTabIndex] = useState<string>('车行道车位')
  const [maxAngle, setMaxAngle] = useState(360);

  const { data: useRateData={}, loading, error } = useRequest(useCallback(() => {
    return GetGrovernUseRateFourData(areaCode);
  }, [areaCode]), {
    formatResult: res => res.data,
    refreshDeps: [areaCode]
  })
  const { data: useRateData1={} } = useRequest(useCallback(() => {
    return GetGrovernUseRateOneData(areaCode);
  }, [areaCode]), {
    formatResult: res => res.data,
    refreshDeps: [areaCode]
  })
  const { data: useRateData2={} } = useRequest(useCallback(() => {
    return GetGrovernUseRateTwoData(areaCode);
  }, [areaCode]), {
    formatResult: res => res.data,
    refreshDeps: [areaCode]
  })
  const { data: useRateData3={} } = useRequest(useCallback(() => {
    return GetGrovernUseRateThreeData(areaCode);
  }, [areaCode]), {
    formatResult: res => res.data,
    refreshDeps: [areaCode]
  })
 
 const { data: parkuseRateData={}, loading:parkloading, error:error1 } = useRequest(useCallback(() => {
    return GetGrovernParkUseRateFourData(areaCode);
  }, [areaCode]), {
    formatResult: res => res.data,
    refreshDeps: [areaCode]
  })
  console.log(parkuseRateData)
  const { data: parkuseRateData1={} } = useRequest(useCallback(() => {
    return GetGrovernParkUseRateOneData(areaCode);
  }, [areaCode]), {
    formatResult: res => res.data,
    refreshDeps: [areaCode]
  })
  const { data: parkuseRateData2={} } = useRequest(useCallback(() => {
    return GetGrovernParkUseRateTwoData(areaCode);
  }, [areaCode]), {
    formatResult: res => res.data,
    refreshDeps: [areaCode]
  })
  const { data: parkuseRateData3={} } = useRequest(useCallback(() => {
    return GetGrovernParkUseRateThreeData(areaCode);
  }, [areaCode]), {
    formatResult: res => res.data,
    refreshDeps: [areaCode]
  })

  

  if (loading) {
    return (
      <Panel title="停车利用率" >
        <div className="fetch_container" style={{ height: 200 }}>
          <Spin />
        </div>
      </Panel>
    )
  }

  if (error) {
    return (
      <Panel title="停车利用率"  >
        <div className="fetch_container" style={{ height: 200 }}>
          请求错误
        </div>
      </Panel>
    )
  }
  
  const defaultData = {
    today: parkuseRateData2?parkuseRateData2['todayUseRate']:0,
    weekday: parkuseRateData?parkuseRateData['weekendUseRate']:0,
    workday: parkuseRateData3?parkuseRateData3['workDayUseRate']:0,
    yesterday: parkuseRateData1?parkuseRateData1['yesterdayUseRate']:0
  }
  let roadBerthData = {
    today: useRateData2?useRateData2['todayUseRate']:0,
    weekday: useRateData?useRateData['weekendUseRate']:0,
    workday: useRateData3?useRateData3['workDayUseRate']:0,
    yesterday: useRateData1?useRateData1['yesterdayUseRate']:0
  }
  // const roadBerthUseRateVo = useRateData?.roadBerthUseRateVo ? useRateData.roadBerthUseRateVo : defaultData;
  // const walkBerthUseRateVo = useRateData?.walkBerthUseRateVo ? useRateData.walkBerthUseRateVo : defaultData;
  let showData = [
    { name: '当日', value: 0, index: 0 }
  ]


  if (activeTab == '人行道车位') {
    showData = [
      { name: '当日', value: Math.round(defaultData.today), index: 0 },
      { name: '昨天', value: Math.round(defaultData.yesterday), index: 1 },
      { name: '工作日', value: Math.round(defaultData.workday), index: 2 },
      { name: '节假日', value: Math.round(defaultData.weekday), index: 3 }
    ]

  } else {
    showData = [
      { name: '当日', value: Math.round(roadBerthData.today), index: 0 },
      { name: '昨天', value: Math.round(roadBerthData.yesterday), index: 1 },
      { name: '工作日', value: Math.round(roadBerthData.workday), index: 2 },
      { name: '节假日', value: Math.round(roadBerthData.weekday), index: 3 }
    ]
  }





  return (
    <Panel
      title="停车利用率"
    >
      <div className={styles.container}>
        <div className={styles.tabs}>
          {
            tabs.map((i) =>
              <div
                className={cn(styles.item, {
                  [styles.active]: activeTab === i
                })}
                key={`tab_${i}`}
                onClick={() => {
                  changeTabIndex(i)

                }}
              >{i}</div>
            )
          }
        </div>
        <RadialBar
          width={270}
          height={174}
          autoFit={false}
          data={showData}
          xField="name"
          yField="value"
          // maxAngle={maxAngle}
          minBarWidth={6}
          maxBarWidth={6}
          colorField="index"
          color={ref => colors[ref.index]}
          barBackground={{
            style: {
              fill: '#2DABED',
              fillOpacity: 0.19
            }
          }}
          xAxis={{
            label: null
          }}
          barStyle={{
            strokeOpacity: 0
          }}
          radius={0.78}
          innerRadius={0.4}
          tooltip={{
            formatter: data => ({
              name: data.name,
              value: `${data.value}%`
            })
          }}
        />
        <div className={styles.legend}>
          {
            showData.map(item => (
              <div className={styles.legend_item} key={item.index}>
                <div>{item.name}</div>
                <div>{item.value}%</div>
              </div>
            ))
          }
        </div>
      </div>
    </Panel>
  )
}

export default KeyCarTable;