import React, { useState, useEffect, useCallback, forwardRef,useMemo, useImperativeHandle } from 'react';
import Modal from './components/modal';
import TopTale from '@/components/topTable';
import { Scrollbars } from 'react-custom-scrollbars';
import { useRequest } from 'ahooks';
import { Spin } from 'antd'
import { GetAlarmListByType,GetParkRank,GetRoadRank ,GetDifficultParkOrRoad,GetDifficultRoadOrRoad} from '@/services/api';
import { observer } from 'mobx-react';
import styles from './index.module.scss';


export type AlarmType = 1 | 2 | 3 | 4 | 5 | 6 | 7 |8;

export type OnAlarmClick = (type: AlarmType) => void;

const alarmTypeNames = {
  1: '设备报警',
  2: '不规范停车报警',
  3: '套牌车报警',
  4: '僵尸车报警',
  5: '敏感车报警',
  6: '水淹报警',
  7:'停车场/停车位周转率排行榜',
  8:'停车难路段/停车场'
}

const AlarmModal:React.FC<{
  visible: boolean;
  onShowIframe: () => void;
  onClose: () => void;
  onAlarmFocus: (roadSectionId: string,parkingNo: string) => void;
  areaCode?: string;
  ref?: any;
  tabNo?: number;
}> = forwardRef(({visible,areaCode,onShowIframe,onClose,onAlarmFocus,tabNo},ref) => {
  const [alarmType,setAlarmType] = useState<AlarmType | null>(null);
  const [changModal,setChangModal] = useState(true);
  const [lotchangModal,setLotChangModal] = useState(true);

  const { data: alarmList = [], run: getAlarmList } = useRequest(useCallback(() => {
    return GetAlarmListByType({
      areaCode,
      alarmType: alarmType as number
    })},[alarmType,areaCode]), {
      manual: true,
    formatResult: res => res.data
  })

//停车场/停车位周转率排行榜
const { data, run: getParkLists,loading:loading2} = useRequest(useCallback(() => {
  return GetParkRank({
    areaCode,
  
  })
}, [areaCode]), {
  manual: true,
  formatResult: res => res.data,
  refreshDeps: [areaCode]
})
const { data: roaddata=[],run: getRoadLists,loading:loading3} = useRequest(useCallback(() => {
  return GetRoadRank({
    areaCode,
    
  })
}, [areaCode]), {
  manual: true,
  formatResult: res => res.data,
  refreshDeps: [areaCode]
}) 
let showData =data?data:[]
const changdata =  (val: boolean)=> {
  setChangModal(val);
}
const lotchangdata =  (val: boolean)=> {
  setLotChangModal(val);
}

//停车难路段/停车场
const { data: fetchData, loading, error, run: getparklotLists } =useRequest( useCallback(() => {
  return   GetDifficultParkOrRoad({
    areaCode: areaCode
  }) 
}, [areaCode]), {
  manual: true,
formatResult: res => res.data
})
const { data: roadData=[],run: getroadlotLists,loading:loading4} = useRequest(useCallback(() => {
  return GetDifficultRoadOrRoad({
    areaCode:areaCode
  })},[areaCode]), {
    manual: true,
  formatResult: res => res.data
})



  const onAlarmClick = (type: AlarmType) => {
    setAlarmType(type);
    setTimeout(() => {
      if(type==7){
        //停车场/停车位周转率排行榜
       // getAlarmLists()
       changModal?getParkLists():getRoadLists()
        
        return
      }else if(type==8){
        //停车难路段/停车场
        lotchangModal? getparklotLists():getroadlotLists()
        return
      }
      getAlarmList();
    },300)
  }





  useEffect(() => {
    if(!visible) {
      setAlarmType(null);
    }
   console.log(tabNo)
    if(tabNo==0){
      setChangModal(false)
      setLotChangModal(true);
    }else{
      setChangModal(true)
      setLotChangModal(false);
    }
  },[visible,tabNo])

  useImperativeHandle(ref, () => ({
    onAlarmClick
  }))

  let comuns=[[
    {
      title: '排行',
      render:(text: any,record: any,index: number)=>`TOP${index+1}`,
      },
    { title: '区域' , dataIndex: 'areaName'},
    { title: changModal?'停车场名称':'路段名称', dataIndex:changModal?'parkName':'roadSectionName'},
    { title: '有效周转率',  dataIndex: 'validTurnover' },
    { title: '总周转率',  dataIndex: 'turnover'},
    { title: '收入', dataIndex: 'income'}
  ],[
    {
      title: '排行',
      render:(text: any,record: any,index: number)=>`TOP${index+1}`,
      },
    { title: '区域' , dataIndex: 'areaName'},
    { title: lotchangModal?'停车场名称':'路段名称', dataIndex:lotchangModal? 'parkName':'roadSectionName'},
    { title: '利用率',  dataIndex:'useRate'},
  ]]
  
  return (
    <Modal
      size={(alarmType&&alarmType<7) ? 'lg' : (alarmType&&alarmType>6) ?'bg': 'sm'}
      visible={visible}
      maskClosable={false}
      onCancel={onClose}
      className={styles.modalbox}
    >

      {alarmType==7||alarmType==8
        ? 
        <div className={`${styles.alarm_modal} ${styles.alarm_box_bg}  ${alarmType ? styles.alarm_modal_lg : ''} `}>
        <div className={styles.alarm_box_contont}>
       <div className={styles.alarm_box}>
         <p className={styles.alarm_box_title}>{alarmType==7?changModal?'停车场周转率排行榜':'停车位周转率排行榜':lotchangModal?'停车场':'停车难路段'}</p>
         {alarmType==7
         ?<span className={styles.alarm_box_span}  onClick={() => changdata(!changModal)}>切换</span>
         :<span className={styles.alarm_box_span}  onClick={() => lotchangdata(!lotchangModal)}>切换</span>
         }
       </div>
       {loading||loading2||loading3||loading4?
       <div className={styles.list_box_contont}>
       <Spin/>
       </div>
       :
       <TopTale
         className="inspector-rate-table"
         dataSource={alarmType==8?lotchangModal?fetchData:roadData:changModal?showData:roaddata}
         style={{height: '100%'}}
         rowKey="key"
         columns={alarmType==7?comuns[0]:comuns[1]}
         scroll={{y: 370}}
       />
      
       }
       </div>
       </div>
       
        :
       <div className={`${styles.alarm_modal} ${alarmType ? styles.alarm_modal_lg : ''} `}>
       
        {alarmType && (
          <div className={styles.alarm_table}>
            <div className={styles.alarm_table_header}>
              <div className={styles.alarm_table_row}>
                <p>{alarmTypeNames[alarmType]}：</p>
                <button onClick={onShowIframe}>派发工单</button>
              </div>
            </div>
            <Scrollbars
              // className={styles.alarm_table_body_wrapper}
              style={{width: 665, height: 140 }}
              renderThumbVertical={props => <div {...props} className={styles.alarm_table_scrollbar} />}
            >
              <div className={styles.alarm_table_body}>
                { alarmList.map((item,index) => (
                  <div className={styles.alarm_table_row} key={`${item.id}${index}`}>
                    <p>{item.alarmInfo}</p>
                    { item.origin === 1 && <img src="/img/icon_monitor.png" onClick={() => onAlarmFocus(item.id,item.parkingNo)} alt=""/> }
                  </div>
                )) }
              </div>
            </Scrollbars>
          </div>
        )}
      </div> 
      }
    </Modal>
  )
})

export default observer(AlarmModal);